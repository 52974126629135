import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import "./styles.scss";
import Section from "../../Section";
import { ImageInterface } from "../../../types/SanityTypes";
import { IMAGE_SIZES } from "../../../constants";

interface BluePrintHeaderInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  title?: string;
  titleIsH1?: boolean;
  subtitle?: string;
  bodyText?: string;
  decorativeImage1: ImageInterface;
  decorativeImage2: ImageInterface;
  decorativeImage3: ImageInterface;
}

const BluePrintHeader: FunctionComponent<BluePrintHeaderInterface> = props => {
  const {
    palleteColorList,
    title,
    titleIsH1,
    subtitle,
    bodyText,
    decorativeImage1,
    decorativeImage2,
    decorativeImage3
  } = props;
  return (
    <Section sectionClassName="c-bluePrint-header" theme={palleteColorList?.bgColor.title}>
      <div className="c-bluePrint-header__content">
        <div className="grid-row">
          <div className="grid-col grid-col-xs-12 grid-col-md-5 row1 col1 d-flex justify-content-center">
            {titleIsH1 ? (
              <h1 className="c-bluePrint-header--title">{title}</h1>
            ) : (
              <div className="c-bluePrint-header--title">{title}</div>
            )}
          </div>
          <div className="grid-col grid-col-xs-12 grid-col-md-7 row2 col2">
            {decorativeImage1 ? (
              <SanityImage
                {...decorativeImage1}
                sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
                width={429}
                height={117}
                htmlWidth={429}
                htmlHeight={117}
                className={`c-bluePrint-header__image`}
              />
            ) : null}
            {decorativeImage2 ? (
              <SanityImage
                {...decorativeImage2}
                sizes={IMAGE_SIZES.CARD}
                width={252}
                height={117}
                htmlWidth={252}
                htmlHeight={117}
                className={`c-bluePrint-header__image is-lastimage`}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="c-bluePrint-header__content">
        <div className="grid-row">
          <div className="grid-col grid-col-xs-12 grid-col-md-1 col-border row2 col1"></div>
          <div className="grid-col grid-col-xs-12 grid-col-md-4 col-border row2 col2 d-flex justify-content-center">
            {decorativeImage3 ? (
              <SanityImage
                {...decorativeImage3}
                sizes={IMAGE_SIZES.CARD}
                width={296}
                height={137}
                htmlWidth={296}
                htmlHeight={137}
                className={`c-bluePrint-header__image c-bluePrint-header__image-subtitle`}
              />
            ) : null}
            {bodyText && <p className="c-bluePrint-header--subtitle visible-mobile">{bodyText}</p>}
          </div>
          <div className="grid-col grid-col-xs-12arre grid-col-md-7 row3 col3 col-border d-flex justify-content-center">
            <div>
              <h2 className="c-bluePrint-header--title">{subtitle}</h2>
              {bodyText && <p className="c-bluePrint-header--subtitle visible-desktop">{bodyText}</p>}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BluePrintHeader;
